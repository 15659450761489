import {
  Flex,
  Card,
  Text,
  Image,
  Box,
  TextInput,
  LoadingOverlay,
  Button,
  BackgroundImage,
  Divider,
} from "@mantine/core";
import { FiMail } from "react-icons/fi";
import { FaChevronRight } from "react-icons/fa";

export const LoginFormComponent = ({ classes, form, images, handleSubmit }) => {
  return (
    <Flex
      style={{ height: "100vh" }}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Card
        shadow="lg"
        padding="2rem"
        radius="md"
        bg="#F7F4F4"
        w="25%"
        miw={320}
        mih={400}
      >
        <Card.Section>
          <Box bg="#F7F4F4" py={20}>
            <Image src={images.logoGrupoEcopetrol} alt="image" />
          </Box>
          <Box className={classes.lineGradientEcopetrol} />
          <Box maw={500} mx="auto" sx={{ position: "relative" }} h="60px">
            <BackgroundImage
              h="60px"
              src={images.clouds}
              className={classes.clouds}
            />
            <Box sx={{ padding: ".5rem" }}>
              <Image maw={80} ml="auto" src={images.logoAve} alt="image" />
            </Box>
          </Box>
        </Card.Section>

        <Box h="70%" w="100%" sx={classes.alignVerticallyCenter}>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              {...form.getInputProps("email")}
              classNames={{
                input: classes.input,
                label: classes.labelSecondary,
              }}
              label="Email"
              placeholder="Ingrese un email"
              icon={<FiMail size="1.2rem" />}
            />

            <Box sx={{ padding: "2rem 0" }}>
              <Button
                fullWidth
                radius="md"
                disabled={!form.isValid()}
                rightIcon={<FaChevronRight size="1.1rem" />}
                style={{
                  backgroundColor: !form.isValid() ? "#c3c5c6" : "#FFDD00",
                }}
                type="submit"
                data-testid="login-button"
              >
                Iniciar sesión
              </Button>
            </Box>
          </form>
          <Box>
            <Text ta="center" c="dark" fw={100} size="sm">
              Al iniciar sesión, acepta los términos de uso y la política de
              privacidad.
            </Text>
          </Box>
          <Divider my={12} />
          <Box>
            <Text ta="center" c="dark" fw={500} size="sm">
              Si usted requiere consultar o cerrar viajes en la versión anterior
              de AVE. Ingrese aquí.
            </Text>
            <Button
              fullWidth
              style={{ backgroundColor: "#D3CF37" }}
              radius="md"
              my={8}
              onClick={() => {
                window.location.href = process.env.URL_AVE1;
              }}
            >
              AVE 1.0
            </Button>
          </Box>
        </Box>
      </Card>
    </Flex>
  );
};
